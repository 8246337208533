<template>
  <div class="product-picture-wrap">
    <picture>
      <source
        v-for="source in imageSources"
        :key="source.type"
        :srcset="source.srcset.join(', ')"
        :type="source.type"
        :sizes="sizes"
      />
      <img
        :loading="loading === 'lazy' ? 'lazy' : 'eager'"
        itemprop="image"
        :role="htmlRole"
        :src="image.src"
        :alt="image.alt"
      />
    </picture>
    <div v-if="discontinued" class="discontinued-overlay">
      <div v-if="discontinuedText">
        Discontinued
        <span v-if="hasReplacement" class="new-version">
          new version available
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      htmlRole: {
        type: String,
        default: "",
      },
      hasReplacement: {
        type: Boolean,
        default: false,
      },
      image: {
        type: Object,
        required: true,
      },
      sizes: {
        type: String,
        default: null,
      },
      discontinued: {
        type: Boolean,
        default: false,
      },
      discontinuedText: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: String,
        default: "eager",
      },
    },
    computed: {
      imageSources() {
        // Group by image type (jpeg, webp)
        const groups =
          this.image.srcset?.reduce((groups, item) => {
            const val = item.type
            groups[val] = groups[val] || []
            groups[val].push(item)
            return groups
          }, {}) || {}
        // create srcsets per image type
        return Object.keys(groups)
          .map((group) => {
            const sources = groups[group]
            return {
              type: group,
              srcset: sources.map((source) => `${source.url} ${source.desc}`),
            }
          })
          .reverse() // we want webp as default
      },
    },
  }
</script>

<style scoped lang="scss">
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .product-picture-wrap {
    width: 100%;
    height: 100%;
    position: relative;
    container-type: inline-size;
  }
  .discontinued-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    font-family: $font-family-headline;
    font-weight: bold;
    line-height: 1.25;
    font-size: 7.5cqw;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .new-version {
    font-weight: normal;
    text-transform: none;
    font-size: .8em;
  }
</style>
